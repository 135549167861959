<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div
      class="row mt-5 justify-content-between"
      v-if="avvioRicercaVerificheAnomalie"
    >
      <div class="col-6">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6 text-center"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_lista_filtrata_anomalie"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
        <ModalEsportaListaAnomalie />
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <template v-if="avvioRicercaVerificheAnomalie">
      <div class="mx-auto my-auto text-center" v-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="
          VERIFICA_ANOMALIE_LIST.length === 0 &&
          loaded &&
          (status == 200 || status == 204)
        "
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun risultato.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
          @click.prevent="$emit('resetFilters')"
        >
          <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
        ></span>
      </div>
      <div
        v-else-if="status != 200 && status != 204"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Si è verificato un errore. Riprovare più tardi.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
        >
          <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
            <i class="bi bi-house fs-2 text-gray-800"> </i
          ></router-link>
          <router-link v-else to="/bacheca-public">
            <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
        ></span>
      </div>

      <div v-else>
        <Datatable
          :table-header="
            filtroAnomalieVerificheAnomalie &&
            ricercaDopoSelezioneSelectAnomalie &&
            filtroAnomalieVerificheAnomalie.colonne_per_persona
              ? tableHeader
              : tableHeader
          "
          :table-data="VERIFICA_ANOMALIE_LIST"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          @sort="setSortOrderColumn"
          @items-per-page-change="
            setFiltroAnomalie('fetchRowsVerificheAnomalie', $event);
            $emit('getVerificheAnomalieList');
          "
          @current-change="
            setCurrentPageVerificheAnomalie($event);
            $emit('getVerificheAnomalieList');
          "
        >
          <template v-slot:cell-comitato="{ row: data }">
            <div class="tab-long">
              {{ data.comitato }}
            </div>
          </template>
          <template v-slot:cell-soggetto="{ row: data }">
            <div class="tab-long">
              {{ data.soggetto }}
            </div>
          </template>
          <template v-slot:cell-note="{ row: data }">
            <div class="tab-long">
              {{ data.note }}
            </div>
          </template>
          <template v-slot:cell-options="{ row: data }">
            <div class="btn-group">
              <a target="_blank" :href="`${data.link}`">
                <i class="bi bi-eye text-dark fs-5"></i>
              </a>
            </div>
          </template>
        </Datatable>
      </div>
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";
import { viewCorsiAggiornamentoList } from "@/requests/alboInsegnateRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { callFunctionAfterAlert } from "@/composables/swAlert";
import ModalEsportaListaAnomalie from "@/components/verifiche-anomalie/ModalEsportaListaAnomalie.vue";
export default {
  name: "TableAlbo",
  components: {
    Datatable,
    Loading,
    ModalEsportaListaAnomalie,
  },
  emits: ["getVerificheAnomalieList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    const refreshVerificheAnomalieList = () => {
      emit("getVerificheAnomalieList");
    };

    const filtroAnomalieVerificheAnomalie = computed(
      () => store.getters.filtroAnomalieVerificheAnomalie
    );

    const tableHeader = ref([
      {
        name: "Comitato",
        key: "comitato",
        sortable: true,
      },
      {
        name: "Soggetto",
        key: "soggetto",
        sortable: true,
      },
      {
        name: "Note",
        key: "note",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const tableHeaderPersone = ref([
      {
        name: "Nome e cognome",
        key: "nome",
        sortable: true,
      },
      {
        name: "Data nascita",
        key: "data_nascita",
        sortable: true,
      },
      {
        name: "Codice fiscale",
        key: "cod_fisc",
        sortable: true,
      },
      {
        name: "Comitato",
        key: "comitato",
        sortable: true,
      },
      {
        name: "Codice affiliazione",
        key: "cod_affiliazione",
        sortable: true,
      },
      {
        name: "Denominazione",
        key: "denominazione",
        sortable: true,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const VERIFICA_ANOMALIE_LIST = computed(() =>
      store.getters.getStateFromName("resultsVERIFICA_ANOMALIE_LIST")
    );

    const setFiltroAnomalie = (nome_variabile, valore_variabile) => {
      store.commit("setFilterVerificheAnomalie", {
        nome_variabile,
        valore_variabile,
      });
    };

    const setCurrentPageVerificheAnomalie = (e) => {
      console.log("setCurrentPageVerificheAnomalie", e);
      store.commit("setCurrentPageVerificheAnomalie", e);
    };

    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnVerificheAnomalie", columnName);
      store.commit("setSortOrderVerificheAnomalie", order);
      emit("getVerificheAnomalieList");
    };

    return {
      setCurrentPageVerificheAnomalie,
      setFiltroAnomalie,
      VERIFICA_ANOMALIE_LIST,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedVERIFICA_ANOMALIE_LIST")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordVERIFICA_ANOMALIE_LIST")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusVERIFICA_ANOMALIE_LIST")
      ),
      avvioRicercaVerificheAnomalie: computed(
        () => store.getters.avvioRicercaVerificheAnomalie
      ),
      refreshVerificheAnomalieList,
      currentPage: computed(() => store.getters.currentPageVerificheAnomalie),
      rowsToSkip: computed(() => store.getters.rowsToSkipVerificheAnomalie),
      fetchRows: computed(() => store.getters.fetchRowsVerificheAnomalie),
      sortColumn: computed(() => store.getters.sortColumnVerificheAnomalie),
      sortOrder: computed(() => store.getters.sortOrderVerificheAnomalie),
      setSortOrderColumn,
      tableHeader,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      filtroAnomalieVerificheAnomalie,
      tableHeaderPersone,
      ricercaDopoSelezioneSelectAnomalie: computed(
        () => store.getters.ricercaDopoSelezioneSelectAnomalie
      ),
    };
  },
};
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
